<script>
import TablePage from '../../../../../components/table_page';
import request, { portUrl } from '../../../../../utils/request';
import ExportModal from '../../../../../components/export_modal';
// import Form from './form';

export default {
  name: 'table-component',
  extends: TablePage,
  components: {
    ExportModal,
  },
  data() {
    return {
      requestUrl: '/upload/excelExportController/list',
      formConfig: {},
      modalConfig: {
        title: '查看附件',
        visible: false,
        type: '',
      },
      options: [],
    };
  },
  async created() {
    await this.getConfigList('download_center');
    this.configs.columns[this.configs.columns.length - 1].noShowMore = true;
    await this.getDict();
    this.searchList.map((v, k) => {
      const item = v;
      if (item.field === 'fileType') {
        this.options.map((a) => {
          const pItem = a;
          pItem.label = pItem.dictValue;
          pItem.value = pItem.dictCode;
          return pItem;
        });
        this.$set(this.searchList[k].render, 'options', this.options);
        this.$forceUpdate();
      }
      return item;
    });
  },
  watch: {
    formData: {
      deep: true,
      handler(val) {
        if (val.fileType) {
          this.searchList.map((v, k) => {
            const item = v;
            if (item.field === 'fileStatus') {
              const index = this.options.findIndex((va) => va.dictCode === val.fileType);
              this.options[index].children.map((a) => {
                const pItem = a;
                pItem.label = pItem.dictValue;
                pItem.value = pItem.dictCode;
                return pItem;
              });
              this.$set(this.searchList[k].render, 'options', this.options[index].children);
              this.$forceUpdate();
            }
            return item;
          });
        }
      },
    },
  },
  methods: {
    async getDict() {
      const resData = await request.get('/mdm/mdmdictdata/dictTree', { dictTypeCode: 'file_type' });
      this.options = resData.result;
    },
    clickVisible({ row, val: { code } }) {
      if ((row.fileType === '1')) {
        if (row.fileStatus === '8') {
          if (code === 'repaly' || code === 'view_prograss') {
            return false;
          }
        } else if (row.fileStatus === '7') {
          if (code === 'download' || code === 'view_prograss') {
            return false;
          }
        } else if (code === 'download' || code === 'repaly') {
          return false;
        }
      } else if (code === 'download' || code === 'repaly') {
        return false;
      }
      return true;
    },
    modalClick({ val, row }) {
      if (val.code === 'download') {
        // window.location.href = row.filePath;
        const a = document.createElement('a');
        a.href = `${portUrl || ''}upload/downloadController/downloadExcelFile?objectName=${row.objectName}`;
        document.body.appendChild(a);
        a.click(); // 下载
        URL.revokeObjectURL(a.href); // 释放URL 对象
        document.body.removeChild(a); // 删除 a 标签
      } else if (val.code === 'view_prograss') {
        this.prograssClick({ val, row });
      }
    },
    // 查看进度
    prograssClick({ val, row }) {
      this.modalName = 'ExportModal';
      let fileParam = {};
      let configCode = '';
      if (row.fileType === '0') {
        configCode = row.fileParam;
        fileParam.sid = row.webSocketId;
      } else {
        fileParam = JSON.parse(row.fileParam);
      }
      this.propsObjInData = {
        code: val.code,
        row,
        ...fileParam,
        configCode,
        visible: true,
        exportMessage: '',
        isExport: row.fileType !== '0',
      };
    },
  },
};
</script>
